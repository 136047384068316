<template>
  <video
    id="video"
    ref="video"
    :width="width"
    :height="height"
    :src="src"
    :autoplay="autoplay"
    :srcObject.prop="stream"
    muted
    playsinline
    :style="styleObject"
  ></video>
</template>

<script>
export default {
  name: "AppWebcam",
  props: {
    autoplay: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    mirror: {
      type: Boolean,
      default: true,
    },
    screenshotFormat: {
      type: String,
      default: "image/jpeg",
    },
  },
  data() {
    return {
      video: "",
      src: "",
      stream: "",
      hasUserMedia: false,
      styleObject: {
        transform: "scale(-1, 1)",
        filter: "FlipH",
      },
    };
  },

  methods: {
    getPhoto() {
      if (!this.hasUserMedia) return null;

      const canvas = this.getCanvas();
      return canvas.toDataURL(this.screenshotFormat);
    },
    getCanvas() {
      if (!this.hasUserMedia) return null;

      const video = this.$refs.video;
      if (!this.ctx) {
        const canvas = document.createElement("canvas");
        canvas.height = 480;
        canvas.width = 640;
        this.canvas = canvas;

        if (this.mirror) {
          const context = canvas.getContext("2d");
          context.translate(canvas.width, 0);
          context.scale(-1, 1);
          this.ctx = context;
        } else {
          this.ctx = canvas.getContext("2d");
        }
      }

      const { ctx, canvas } = this;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      return canvas;
    },
  },

  mounted: function () {
    this.video = this.$refs.video;
    // const avideo = document.getElementById("video");

    // const openMediaDevices = async (constraints) => {
    //   return await navigator.mediaDevices.getUserMedia(constraints);
    // };

    try {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
          console.log("Got MediaStream:", stream.active);
          this.src = stream;
          this.stream = stream;
          this.hasUserMedia = true;
        });
    } catch (error) {
      console.error("Error accessing media devices.", error);
    }
  },

  beforeDestroy: function () {
    this.video.pause();
    this.src = "";
    this.stream.getTracks()[0].stop();
  },

  destroyed: function () {
    console.log("Destroyed");
  },
};
</script>

<style scoped></style>
